import React from 'react';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'
import Header from './components/header_control/header.js';
import './Agregar.css';

import { useNavigate } from 'react-router-dom';


import exito from './img/exito.png';
import error from './img/error.png'

import { returnUri } from './uri';



function View() {
    const navigate = useNavigate();
    const params = useParams();
    //console.log(params.id)
    const [showJuvenilSection, setShowJuvenilSection] = useState(false);
    const [uri, setUri] = useState("");
    useEffect(() => {
        const fetchData = async (enlace, index) => {
            try {
                const response = await fetch(enlace, {
                    method: 'GET',
                });

                if (!response.ok) {
                    throw new Error('Error de conexión. Asegúrate de que el servidor esté en ejecución.');
                }
                const jsonData = await response.json();
                var i;
                for (i = 0; i < jsonData.length; i++) {
                    if (jsonData[i].id == index) {
                        console.log(index)
                        setFormulario({
                            id: jsonData[i].id,
                            producto: jsonData[i].producto,
                            genero: jsonData[i].genero,
                            modelo: jsonData[i].modelo,
                            tallaInfantil: jsonData[i].tallaInfantil,
                            tallaJuvenil: jsonData[i].tallaJuvenil,
                            precioInfantil: jsonData[i].precioInfantil,
                            precioJuvenil: jsonData[i].precioJuvenil,
                            descripcion: jsonData[i].descripcion,
                            stock: jsonData[i].stock,
                            photos: [null, null, null, null],
                            NameColor: ['', '', '', '', '', '', '', '', ''],
                            colores: [null, null, null, null, null, null, null, null, null] // 
                        });
                        if(jsonData[i].tallaJuvenil !== ""){
                            setShowJuvenilSection(!showJuvenilSection);
                        }
                    }
                }
                //setData(jsonData.filter(item => item.id === parseInt(index)));

                if (jsonData.error) {
                    throw new Error(jsonData.error);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData(`${returnUri()}/data`, params.id);
        setUri(`${returnUri()}/api/modify`);
    }, [params.id]);


    const [imagenValida, setImagenValida] = useState(false);
    const [formulario, setFormulario] = useState({
        id: 0,
        producto: '',
        genero: '',
        modelo: '',
        tallaInfantil: '',
        tallaJuvenil: '',
        precioInfantil: '0',
        precioJuvenil: '0',
        descripcion: '',
        stock: true,
        photos: [null, null, null, null], // Array para almacenar las imágenes adicionales
        NameColor: ['', '', '', '', '', '', '', '', ''],
        colores: [null, null, null, null, null, null, null, null, null] // Array para almacenar los colores seleccionados
    });
    const [formularioEnviado, setFormularioEnviado] = useState(false);

    const [exitoVisible, setExitoVisible] = useState(false);
    const [errorVisible, setErrorVisible] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');


    const handleColorNameChange = (e, colorIndex) => {
        const { value } = e.target;
        const nuevasNameColor = [...formulario.NameColor];
        nuevasNameColor[colorIndex] = value;

        setFormulario({
            ...formulario,
            NameColor: nuevasNameColor,
        });
    };

    const handleColorImageChange = (e, colorIndex) => {
        const { files } = e.target;
        const allowedFormats = ['image/jpeg', 'image/jpg', 'image/png'];
        const input = document.getElementById(`colorImage_${colorIndex}`);
        const nameInput = document.getElementById(`colorName_${colorIndex}`);
        const error = document.getElementById('ColorImgsError');

        if (files && files.length > 0) {
            const selectedImage = files[0];

            if (!allowedFormats.includes(selectedImage.type)) {
                error.textContent = 'Formato de archivo no válido. Se permiten solo archivos jpg, jpeg o png.';
                input.value = '';
            } else {
                if (selectedImage.size > 5 * 1024 * 1024) {
                    error.textContent = `El archivo es demasiado grande. No debe exceder los 5 MB.`;
                    input.value = '';
                } else {
                    error.textContent = '';
                    input.disabled = true;
                    nameInput.disabled = false;

                    const nuevasColores = [...formulario.colores];
                    nuevasColores[colorIndex] = selectedImage;

                    setFormulario({
                        ...formulario,
                        colores: nuevasColores,
                    });
                }
            }
        }
    };

    const handleRemoveColorImage = (colorIndex, final) => {
        console.log("Final: ", final)
        const input = document.getElementById(`colorImage_${final}`);
        const nameInput = document.getElementById(`colorName_${final}`);
        const nuevasColores = [...formulario.colores];
        nuevasColores[colorIndex] = null;

        setFormulario({
            ...formulario,
            colores: nuevasColores,
        });

        reorderColores(colorIndex);
        input.value = '';
        input.disabled = false;
        nameInput.disabled = true;
    };

    const reorderColores = (removedIndex) => {
        const nuevasColores = formulario.colores.filter((_, index) => index !== removedIndex);
        const nuevosNameColor = formulario.NameColor.filter((_, index) => index !== removedIndex);

        setFormulario({
            ...formulario,
            colores: nuevasColores,
            NameColor: nuevosNameColor,
        });
    };

    const validateColorData = () => {
        const { colores, NameColor } = formulario;

        // Filtra los elementos no vacíos en NameColor y los elementos no nulos en colores
        const filteredNameColor = NameColor.filter(name => name.trim() !== '');
        const filteredColores = colores.filter(color => color !== null);

        // Verifica si el número de elementos no vacíos en NameColor es igual al número de elementos no nulos en colores
        return filteredNameColor.length === filteredColores.length;
    };


    const OkExito = () => {
        navigate('/api/modificar');
    };

    const okError = () => {
        // Lógica para cancelar eliminación (si es necesario)
        setErrorVisible(false);
        setErrorMessage('');
        setFormularioEnviado(false);
    };


    const handleInputChange = (e) => {
        const { name, value, type, files } = e.target;
        if (name === 'showJuvenil') {
            setShowJuvenilSection(!showJuvenilSection);

            if (!showJuvenilSection) {
                setFormulario((prevState) => ({
                    ...prevState,
                    tallaJuvenil: '',
                    precioJuvenil: '0',
                }));
            }
        }
        const allowedFormats = ['image/jpeg', 'image/jpg', 'image/png'];
        // Resetear el valor del input de tipo file
        const input = document.getElementById('imagen');
        const error = document.getElementById('ImgError')
        setFormulario(prevState => {
            // Utiliza una función de actualización del estado
            if (type === 'file' && files && files.length > 0) {
                const selectedImage = files[0];

                if (!allowedFormats.includes(selectedImage.type)) {
                    error.textContent = 'Formato de archivo no válido. Se permiten solo archivos jpg, jpeg o png.';
                    setImagenValida(false);
                    input.value = '';
                    return {
                        ...prevState,
                    };
                } else {
                    if (selectedImage.size > 5 * 1024 * 1024) {
                        console.log("Tamaño")
                        error.textContent = `El archivo es demasiado grande. No debe exceder los 5 MB.`;
                        setImagenValida(false);
                        input.value = '';
                        return {
                            ...prevState,
                        };
                    } else {
                        error.textContent = '';
                        const nuevasPhotos = [selectedImage, ...prevState.photos.slice(1)]; // Almacenar en la posición 0

                        setImagenValida(true);
                        input.disabled = true;
                        return {
                            ...prevState,
                            photos: nuevasPhotos,
                        };
                    }
                }

            } else {

                const numericValue = type === 'number' ? value.replace(/[^0-9]/g, '') : value;

                return { ...prevState, [name]: numericValue };
            }
        });

        if (formulario.genero === 'dama' || formulario.genero === 'caballero') {
            // Utiliza la función de actualización del estado aquí también
            setFormulario(prevState => ({
                ...prevState,
                tallaJuvenil: '',
                precioJuvenil: '0',
            }));
        }
    };

    const handleRemoveImage = () => {
        const input = document.getElementById('imagen');
        setImagenValida(false);
        setFormulario((prevState) => {
            const nuevasPhotos = [null, ...prevState.photos.slice(1)]; // Colocar null en la posición 0
            return {
                ...prevState,
                photos: nuevasPhotos,
            };
        });
        input.value = "";
        input.disabled = false;
    };


    const handleInputChangeExtras = (e, index) => {
        const { files } = e.target;
        const allowedFormats = ['image/jpeg', 'image/jpg', 'image/png'];
        const input = document.getElementById(`imagenExtra_${index}`);
        const error = document.getElementById('ImgsError');

        if (files && files.length > 0) {
            const selectedImage = files[0];

            if (!allowedFormats.includes(selectedImage.type)) {
                error.textContent = 'Formato de archivo no válido. Se permiten solo archivos jpg, jpeg o png.';
                input.value = '';
            } else {
                if (selectedImage.size > 5 * 1024 * 1024) {
                    error.textContent = `El archivo es demasiado grande. No debe exceder los 5 MB.`;
                    input.value = '';
                } else {
                    error.textContent = '';
                    input.disabled = true;

                    const nuevasphotos = [...formulario.photos];
                    nuevasphotos[index] = selectedImage;

                    setFormulario({
                        ...formulario,
                        photos: nuevasphotos,
                    });
                }
            }

        }

    };

    const handleRemoveImageExtra = (index) => {
        const input = document.getElementById(`imagenExtra_${index}`);
        const nuevasphotos = [...formulario.photos];
        nuevasphotos[index] = null;

        setFormulario({
            ...formulario,
            photos: nuevasphotos,
        });
        input.value = '';
        input.disabled = false;
    };


    // const handleColorChange = (color) => {
    //     // Manejar cambios en los colores seleccionados
    //     const colores = formulario.colores.includes(color)
    //         ? formulario.colores.filter((c) => c !== color)
    //         : [...formulario.colores, color];

    //     setFormulario({ ...formulario, colores });
    // };

    const uploadData = (e) => {
        e.preventDefault();
        setFormularioEnviado(true)

        var formData = new FormData()
        formData.append('id', formulario.id)
        formData.append('producto', formulario.producto);
        formData.append('genero', formulario.genero);
        formData.append('modelo', formulario.modelo);
        formData.append('tallaInfantil', formulario.tallaInfantil);
        formData.append('tallaJuvenil', formulario.tallaJuvenil);
        formData.append('precioInfantil', formulario.precioInfantil);
        formData.append('precioJuvenil', formulario.precioJuvenil);
        formData.append('descripcion', formulario.descripcion);
        formData.append('stock', formulario.stock);



        for (const color of formulario.NameColor) {
            // Verifica si el color es null y detiene el ciclo si es así
            if (color === '') {
                break;
            }
            formData.append('nombrecolores[]', color);
        }

        for (const color of formulario.colores) {
            // Verifica si el color es null y detiene el ciclo si es así
            if (color === null) {
                break;
            }
            formData.append('colores', color);
        }

        // Agrega las fotos al FormData
        formulario.photos.forEach((photo, index) => {
            if (photo) {

                formData.append(`photos`, photo);
            }
        });

        console.log("Entre al upload")
        fetch(uri, {
            method: 'POST',
            body: formData
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Error de conexión. Asegúrate de que el servidor esté en ejecución.');
                }
                return response.json();
            })
            .then(data => {
                if (data.error) {
                    throw new Error(data.error);
                } else {
                    console.log(data);
                    setExitoVisible(true);
                }
            })
            .catch(error => {
                setErrorMessage(error.message);
                setErrorVisible(true);
                throw new Error(error);
            });
    };

    const { producto, genero, modelo, tallaInfantil, tallaJuvenil, precioInfantil, precioJuvenil, descripcion, stock } = formulario;

    return (
        <div>
            <main>
                <Header />
                <form onSubmit={uploadData} id="formula">
                    <div>
                        <label htmlFor="ID">ID:</label>
                        <input type="text" id="ID" name="ID" value={formulario.id + 1} disabled={true} />

                        <label htmlFor="producto">Producto:</label>
                        <input type="text" id="producto" name="producto" value={producto} onChange={handleInputChange} required />


                        <h4>Género:</h4>
                        <div className='gen'>
                            <div>
                                <input
                                    type="radio"
                                    id="niño"
                                    name="genero"
                                    value="niño"
                                    checked={genero === 'niño'}
                                    onChange={handleInputChange}
                                />
                                <label htmlFor="niño">Niño</label>
                            </div>
                            <div>
                                <input
                                    type="radio"
                                    id="niña"
                                    name="genero"
                                    value="niña"
                                    checked={genero === 'niña'}
                                    onChange={handleInputChange}
                                />
                                <label htmlFor="niña">Niña</label>
                            </div>
                            <div>
                                <input
                                    type="radio"
                                    id="caballero"
                                    name="genero"
                                    value="caballero"
                                    checked={genero === 'caballero'}
                                    onChange={handleInputChange}
                                />
                                <label htmlFor="caballero">Caballero</label>
                            </div>
                            <div>
                                <input
                                    type="radio"
                                    id="dama"
                                    name="genero"
                                    value="dama"
                                    checked={genero === 'dama'}
                                    onChange={handleInputChange}
                                />
                                <label htmlFor="dama">Dama</label>
                            </div>
                        </div>

                        <label htmlFor="modelo">Modelo:</label>
                        <input type="text" id="modelo" name="modelo" value={modelo} onChange={handleInputChange} required />

                        {genero === 'dama' || genero === 'caballero' ? (
                            <section>
                                <label htmlFor="tallaInfantil">Talla:</label>
                                <input type="text" id="tallaInfantil" name="tallaInfantil" value={tallaInfantil} onChange={handleInputChange} required />

                                <label htmlFor="precioInfantil">Precio:</label>
                                <input type="text" id="precioInfantil" name="precioInfantil" value={precioInfantil} onChange={handleInputChange} required />

                            </section>


                        ) : (

                            <section>
                                <label htmlFor="tallaInfantil">Talla Infantil:</label>
                                <input type="text" id="tallaInfantil" name="tallaInfantil" value={tallaInfantil} onChange={handleInputChange} required />

                                <label htmlFor="precioInfantil">Precio Infantil:</label>
                                <input type="number" id="precioInfantil" name="precioInfantil" value={precioInfantil} onChange={handleInputChange} required />




                                <div className='checkYoung'>
                                    <input
                                        type="checkbox"
                                        id="showJuvenil"
                                        name="showJuvenil"
                                        checked={showJuvenilSection}
                                        onChange={handleInputChange}
                                    />
                                    <label htmlFor="showJuvenil">Juvenil</label>
                                </div>

                                {showJuvenilSection && (
                                    <section>
                                        <label htmlFor="tallaJuvenil">Talla Juvenil:</label>
                                        <input type="text" id="tallaJuvenil" name="tallaJuvenil" value={tallaJuvenil} onChange={handleInputChange} />

                                        <label htmlFor="precioJuvenil">Precio Juvenil:</label>
                                        <input type="number" id="precioJuvenil" name="precioJuvenil" value={precioJuvenil} onChange={handleInputChange} required />
                                    </section>
                                )}
                            </section>
                        )}





                        <label htmlFor="descripcion">Descripción:</label>
                        <textarea id="descripcion" name="descripcion" value={descripcion} onChange={handleInputChange} required />

                        <label htmlFor="stock">Stock:</label>
                        <select id="stock" name="stock" value={stock} onChange={handleInputChange} required>
                            <option value={true}>Sí</option>
                            <option value={false}>No</option>
                        </select>



                    </div>
                    <div>
                        <h4>Imagen principal:</h4>
                        <p>Solo archivos jpg, jpeg, png &#40;máximo 5 mb&#41;</p>
                        <p id="ImgError" className='error'></p>
                        {formulario.photos[0] && (
                            <div className='preview'>
                                <img
                                    alt="Previsualización"
                                    src={URL.createObjectURL(formulario.photos[0])}
                                />
                                <button type="button" onClick={handleRemoveImage}>
                                    Eliminar
                                </button>
                            </div>
                        )}
                        <input
                            type="file"
                            id="imagen"
                            name="imagen"
                            accept="image/*"
                            onChange={handleInputChange}
                            required
                        />
                        <h4>Imágenes extras (máximo 3):</h4>
                        <p>Solo archivos jpg, jpeg, png &#40;máximo 5 mb por imagen&#41;</p>
                        <p id="ImgsError" className='error'></p>
                        <div className="Igrid">
                            {formulario.photos.map((imagen, index) => {
                                const imageIndex = index + 1; // Ajustamos el índice para empezar desde 1
                                if (imageIndex < formulario.photos.length) {
                                    return (
                                        <section key={`imagenExtra_${imageIndex}`}>
                                            {formulario.photos[imageIndex] && (
                                                <div className='preview'>
                                                    <img
                                                        alt={`Previsualización ${imageIndex}`}
                                                        src={URL.createObjectURL(formulario.photos[imageIndex])}
                                                        className="preview"
                                                    />
                                                    <button type="button" onClick={() => handleRemoveImageExtra(imageIndex)}>
                                                        Eliminar
                                                    </button>
                                                </div>
                                            )}
                                            <input
                                                type="file"
                                                id={`imagenExtra_${imageIndex}`}
                                                name={`imagenExtra_${imageIndex}`}
                                                accept="image/*"
                                                onChange={(e) => handleInputChangeExtras(e, imageIndex)}
                                            />
                                        </section>
                                    );
                                }
                                return null; // No renderizamos nada si el índice está fuera del rango
                            })}
                        </div>

                        <h4>Colores:</h4>
                        <p>Solo archivos jpg, jpeg, png &#40;máximo 5 mb por imagen&#41;</p>
                        <p id="ColorImgsError" className='error'></p>
                        <div className="Igrid">
                            {(() => {
                                const inputsColor = formulario.colores.filter(color => color !== null).length;

                                const elements = [];
                                for (let colorN = 0; colorN <= inputsColor; colorN++) {
                                    if (colorN < 9) {
                                        elements.push(
                                            <section key={`colorImage_${colorN}`}>
                                                <label htmlFor={`colorName_${colorN}`}>Nombre del color {colorN + 1}: </label>
                                                <input
                                                    type='text'
                                                    id={`colorName_${colorN}`}
                                                    value={formulario.NameColor[colorN]}
                                                    onChange={(e) => handleColorNameChange(e, colorN)}
                                                    disabled />
                                                {formulario.colores[colorN] && (
                                                    <div className='preview'>
                                                        <img
                                                            alt={`Previsualización ${colorN}`}
                                                            src={URL.createObjectURL(formulario.colores[colorN])}
                                                            className="preview"
                                                        />
                                                        <button type="button" onClick={() => handleRemoveColorImage(colorN, inputsColor - 1)}>
                                                            Eliminar
                                                        </button>
                                                    </div>
                                                )}
                                                <input
                                                    type="file"
                                                    id={`colorImage_${colorN}`}
                                                    name={`colorImage_${colorN}`}
                                                    accept="image/*"
                                                    onChange={(e) => handleColorImageChange(e, colorN)}
                                                />
                                            </section>
                                        );
                                    }

                                }
                                return elements;
                            })()}
                        </div>
                    </div>
                    <h3 className='advice'>Nota: Si modifica algo debe subir nuevamente las fotos</h3>
                    <button className='send' type="submit" disabled={!validateColorData() || !producto ||
                        !genero ||
                        !modelo ||
                        !tallaInfantil ||
                        !precioInfantil ||
                        (!tallaJuvenil && showJuvenilSection) ||
                        (!precioJuvenil && showJuvenilSection) ||
                        !descripcion ||
                        !imagenValida ||
                        formularioEnviado}>
                        Modificar producto
                    </button>
                </form>
                {exitoVisible && (
                    <div className="modal_1">
                        <img alt="exito" src={exito} />
                        <h2>Éxito</h2>
                        <p>El producto se ha modificado correctamente.</p>
                        <button onClick={OkExito}>OK</button>
                    </div>
                )}

                {/* Modal de error */}
                {errorVisible && (
                    <div className="modal_1">
                        <img alt="error" src={error} />
                        <h2>Error</h2>
                        <p>Ocurrió un error al intentar modificar el producto.</p>
                        <p>{errorMessage}</p>
                        <button onClick={okError}>OK</button>
                    </div>
                )}
            </main>
        </div >
    );
}

export default View;