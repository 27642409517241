import './header.css'
import logo from './t-logo.jpg'
import React, { useEffect, useState } from 'react';
import {Link} from 'react-router-dom'
function Header() {
  const [isOpen,setIsOpen]=useState(false)
  const [uri, setUri] = useState("");
    useEffect(() => {
        setUri(`${window.location.protocol}//${window.location.hostname}/api/logout`);
    }, []);
  const fetchData = async () => {
    try {
        const response = await fetch(uri, {
            method: 'GET',
        });

        if (!response.ok) {
            throw new Error('Error de conexión. Asegúrate de que el servidor esté en ejecución.');
        }

        const jsonData = await response.json();

        if (jsonData.error) {
            throw new Error(jsonData.error);
        } else {
            console.log(jsonData);
            window.location.reload();
        }
    } catch (error) {
        console.log('Connection error');
        
        console.error('Error fetching data:', error);
    }
};

  return (
    <header className="header" id="top">
      <Link to="/" className='logo'>
        <img src={logo} alt="logo"></img>
      </Link>
      <nav className={`nav-items ${isOpen && "open"}`}>
        <Link to="/api">Inicio</Link>
        <Link to="#" onClick={fetchData}>Cerrar Sesión</Link>
      </nav>    
      <div className={`toggle ${isOpen && "open"}`} onClick={()=>setIsOpen(!isOpen)}>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </header>
  );
}

export default Header;

