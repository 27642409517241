import './Product.css'
import what from './components/footer/icons/whatsapp.svg'
import React from 'react'
import { useParams } from 'react-router-dom'
import Footer from './components/footer/footer';
import Header from './components/header/header';
import { useState, useEffect } from 'react';
import { useRef } from 'react';
import gif from '../src/img/loading.gif'

import { returnUri } from './uri.js';




function Product() {
    const enlaceRef = useRef(null);
    const params = useParams();
    const [data, setData] = useState([]);
    useEffect(() => {
        const fetchData = async (enlace) => {
            try {
                const response = await fetch(enlace, {
                    method: 'GET',
                });

                if (!response.ok) {
                    throw new Error('Error de conexión. Asegúrate de que el servidor esté en ejecución.');
                }
                const jsonData = await response.json();
                setData(jsonData);
                //console.log(jsonData)
                if (jsonData.error) {
                    throw new Error(jsonData.error);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData(`${returnUri()}/data`);
    }, []);
    console.log(data);
    const [uri, setUri] = useState('');
    useEffect(() => {
        setUri(`${returnUri()}`)
    }, []);


    // let c =
    // [
    //     { "nombre": "Barrido", "valor": "", "ruta": uri +"/colors/barrido.png" },
    //     { "nombre": "Blinch 1", "valor": "", "ruta": uri +"/colors/blinch_1.png" },
    //     { "nombre": "Blinch 2", "valor": "", "ruta": uri +"/colors/blinch_2.png" },
    //     { "nombre": "Marino 1", "valor": "", "ruta": uri +"/colors/marino_1.png" },
    //     { "nombre": "Marino 2", "valor": "", "ruta": uri +"/colors/marino_2.png" },
    //     { "nombre": "Marmoleado", "valor": "", "ruta": uri +"/colors/marmoleado.png" },
    //     { "nombre": "Negro 1", "valor": "", "ruta": uri +"/colors/negro_1.png" },
    //     { "nombre": "Negro 2", "valor": "", "ruta": uri +"/colors/negro_2.png" },
    //     { "nombre": "Negro 3", "valor": "", "ruta": uri +"/colors/negro_3.png" },
    //     { "nombre": "Negro liso", "valor": "", "ruta": uri +"/colors/negro_liso.png" },
    //     { "nombre": "Stone", "valor": "", "ruta": uri +"/colors/stone.png" },
    //     { "nombre": "Azul Marino Barrido", "valor": "#0b0c18", "ruta": "" },
    //     { "nombre": "Beige", "valor": "#9f7f64", "ruta": "" },
    //     { "nombre": "Marron", "valor": "#8e4218", "ruta": "" },
    //     { "nombre": "Negro 4", "valor": "#141414", "ruta": "" },
    //     { "nombre": "Sky Blue", "valor": "#405383", "ruta": "" },
    //     { "nombre": "Suavizado 1", "valor": "#383642", "ruta": "" },
    //     { "nombre": "Suavizado 2", "valor": "#282f46", "ruta": "" },
    //     { "nombre": "Suavizado 3", "valor": "#485679", "ruta": "" },
    //     { "nombre": "Turquesa 1", "valor": "#353a5b", "ruta": "" },
    //     { "nombre": "Turquesa 2", "valor": "#a0c1d6", "ruta": "" },
    //     { "nombre": "Turquesa Barrido", "valor": "#a0c1d6", "ruta": "" },
    //     { "nombre": "Vino", "valor": "#611829", "ruta": "" }
    // ];

    const [imagenSeleccionada, setImagenSeleccionada] = useState("");

    function seleccionarImagen(e) {
        const imagenActual = e.target;
        const imagenes = document.getElementsByClassName("selectable");
        for (let i = 0; i < imagenes.length; i++) {
            imagenes[i].classList.remove("selected");
        }
        imagenActual.classList.add("selected");
        setImagenSeleccionada(imagenActual.src);
    }
    function enviarWhatsApp() {
        var url_actual = encodeURIComponent(window.location.href);
        var whatsapp_url = "https://api.whatsapp.com/send?phone=+5212228836668&text=" + url_actual + encodeURIComponent("\n¡Hola! Me gustaria más información acerca de este producto");
        enlaceRef.current.setAttribute('href', whatsapp_url);
    }

    if (!data.length || !data[params.id]) {
        // Loading state or handle error state
        return <div className='cargando'><img src={gif} alt='cargando' /></div>;
    } else {
        return (
            <div>
                <Header></Header>
                <main className='product'>
                    {data[params.id].stock === 'true' ? null : <p className='red'>Agotado</p>}
                    <div className='muestra'>
                        <img key={imagenSeleccionada === "" ? `${uri}/imgs/${data[params.id].photos[0]}` : imagenSeleccionada} alt='producto' src={imagenSeleccionada === "" ? `${uri}/imgs/${data[params.id].photos[0]}` : imagenSeleccionada}></img>
                        <section>
                            {
                                data[params.id].photos.map((e) => {
                                    return <img key={e} alt='producto' src={uri + "/imgs/" + e} className={imagenSeleccionada === `${uri}/imgs/${e}` ? "selectable selected" : "selectable"} onClick={seleccionarImagen}></img>
                                })
                            }
                        </section>
                    </div>
                    <section className='about'>
                        {
                            data[params.id].producto === data[params.id].modelo ? <h1>{data[params.id].producto.charAt(0).toUpperCase() + data[params.id].producto.slice(1)}</h1> : <h1>{data[params.id].producto.charAt(0).toUpperCase() + data[params.id].producto.slice(1) + " " + data[params.id].modelo.charAt(0).toUpperCase() + data[params.id].modelo.slice(1)}</h1>
                        }
                        <p>{data[params.id].descripcion + "."}</p>
                        {
                            parseInt(data[params.id].precioInfantil) === 0 || data[params.id].genero === "niño" || data[params.id].genero === "niña" ? null : <h2>Precio: ${data[params.id].precioInfantil}</h2>
                        }
                        {
                            parseInt(data[params.id].precioInfantil) === 0 || data[params.id].genero === "caballero" || data[params.id].genero === "dama" ? null : <h2>Infantil: ${data[params.id].precioInfantil}</h2>
                        }
                        {
                            parseInt(data[params.id].precioJuvenil) === 0 ? null : <h2>Juvenil: ${data[params.id].precioJuvenil}</h2>
                        }
                        <div className='square'>
                            <h3>Tallas:</h3>
                            {
                                parseInt(data[params.id].precioInfantil) === 0 || data[params.id].genero === "niño" || data[params.id].genero === "niña" ? null : <p>{data[params.id].tallaInfantil}</p>
                            }
                            {
                                data[params.id].tallaInfantil === '' || data[params.id].genero === "caballero" || data[params.id].genero === "dama" ? null : <p>Infantil: {data[params.id].tallaInfantil}</p>
                            }
                            {
                                data[params.id].tallaJuvenil === '' ? null : <p>Juvenil: {data[params.id].tallaJuvenil}</p>
                            }

                            <section>
                                {
                                    data[params.id].colors && data[params.id].colors.length > 0 && (
                                        <section>
                                            {data[params.id].colors.map(color => (
                                                <div key={color.nombre}>
                                                    <figure>
                                                        <img alt="img" className='colores_img' src={uri + '/colors/' + color.img}></img>
                                                    </figure>
                                                    <figcaption>{color.nombre}</figcaption>
                                                </div>
                                            ))}
                                        </section>
                                    )
                                }
                            </section>
                            {/* <h3>Colores disponibles:</h3>
                            <section>
                                {
                                    data[params.id].colores.length === 0 ? null : colores(data[params.id].colores)
                                }
                                {
                                    data[params.id].coloresHex.length === 0 ? null : coloresHex(data[params.id].coloresHex)
                                }
                            </section> */}
                        </div>
                        <a href="#" className="compra" ref={enlaceRef} onClick={enviarWhatsApp}>
                            <img id='whats' src={what} alt="whatsapp" />
                            <p>Más detalles</p>
                        </a>
                    </section>
                </main>
                <Footer></Footer>
            </div>
        )
    }

}

export default Product


