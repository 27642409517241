
import './Home.css'
import { Link } from 'react-router-dom'
import nina from './img/nina.png'
import nino from './img/nino.png'
import caballero from './img/caballero.png'
import dama from './img/dama.png'
import ban from './img/banner-tiburon.png'
import ban1 from './img/banner.png'
import Header from './components/header/header.js';
import Footer from './components/footer/footer.js'
import React, { useEffect, useState } from 'react';

import { returnUri } from './uri.js';

function Home() {
  const [uri, setUri] = useState('');
    useEffect(() => {
        setUri(returnUri())
    }, []);

  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <div>
      <Header></Header>
      <main className='content'>

        <img src={ban} className='banner' id='movil' alt='banner'></img>
        <img src={ban1} className='banner' id='pc' alt='banner'></img>
        <h1 className="titulo">Tiburoncito Jeans:<span className='span'> Visita nuestro catálogo</span> </h1>
        <h2 className="subtitulo" id='subtitulo'>Explora nuestro catálogo</h2>
        <div className='grid'>
          <Link to="/Catalogo/niño">
            <div>
              <img src={nino} alt='prueba'></img>
              <p>Niño</p>
            </div>
          </Link>
          <Link to="/Catalogo/niña">
            <div>
              <img src={nina} alt='prueba'></img>
              <p>Niña</p>
            </div>
          </Link>
          <Link to="/Catalogo/caballero">
            <div>
              <img src={caballero} alt='prueba'></img>
              <p>Caballero</p>
            </div>
          </Link>
          <Link to="/Catalogo/dama">
            <div>
              <img src={dama} alt='prueba'></img>
              <p>Dama</p>
            </div>
            </Link>
        </div>
        <container>
          <img alt='prueba' src={uri+"/muestra1.jpeg"}></img>
          <img alt='prueba' src={uri+"/muestra2.jpeg"}></img>
        </container>
        <div className="box">
          <section>
            <h2 className="subtitulo">Cool kids wear Tiburoncito Jeans.</h2>
            <p className='lore'>Bienvenidos a Tiburoncito Jeans, la tienda de ropa para niños cool. Aquí encontrarás una amplia selección de ropa a la moda para tus pequeños. Nuestro equipo está comprometido en ofrecer prendas de calidad con diseños únicos y frescos que harán que tu hijo o hija luzca como todo un fashionista.</p>
            <p className='lore'>En Tiburoncito Jeans, somos un equipo de expertos en moda infantil que tienen una amplia experiencia en el diseño y la fabricación de ropa para niños. Nos aseguramos de que cada prenda que vendemos sea de alta calidad, duradera y cómoda para que tus hijos se sientan felices y cómodos en todo momento. Si estás buscando una tienda de ropa para niños donde puedas encontrar prendas únicas y de calidad, no busques más allá de Tiburoncito Jeans.</p>
          </section>
          <section>
            <h2 className='subtitulo'>¡Pregunta por los precios para mayoristas!</h2>
            <Link to='/Contacto' onClick={handleClick}>
              <div className='b-contac'>
                Click para contactarnos
              </div>
            </Link>
            <p className='lore'>En Tiburoncito Jeans siempre estamos listos para ayudar. Ya sea que necesites encontrar la talla adecuada o asesoramiento sobre qué prenda combina mejor con otra, estamos disponible para ayudarte en todo lo que necesites. Nuestro objetivo es asegurarnos de que tu experiencia de compra en nuestra tienda sea lo más fácil y agradable posible.</p>
          </section>
        </div>
      </main>
      <Footer></Footer>
    </div>
  );
}

export default Home;