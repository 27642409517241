import './header.css'
import logo from './t-logo.jpg'
import { useState } from 'react';
import {Link} from 'react-router-dom'
function Header() {
  const [isOpen,setIsOpen]=useState(false)
  return (
    <header className="header" id="top">
      <Link to="/" className='logo'>
        <img src={logo} alt="logo"></img>
      </Link>
      <nav className={`nav-items ${isOpen && "open"}`}>
        <Link to="/">Inicio</Link>
        <Link to="/Catalogo">Catalogo</Link>
        <Link to="/Contacto">Contacto</Link>
      </nav>    
      <div className={`toggle ${isOpen && "open"}`} onClick={()=>setIsOpen(!isOpen)}>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </header>
  );
}

export default Header;

