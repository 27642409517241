
import { Link } from 'react-router-dom'
import React, { useEffect, useState } from 'react';

import { returnUri } from '../../uri';

function Card(props) {
    const handleClick = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };
    const [uri, setUri] = useState('');
    useEffect(() => {
        setUri(`${returnUri()}`)
    }, []);
    return (
        <Link key={props.id + "A"} className="container" to={"/Product/" + props.id} onClick={handleClick}>
            <div className="container-img">
                <img alt="s" src={uri +"/imgs/" + props.image}></img>
                { props.stock ? null : <span className='agotado'>Agotado</span>}
            </div>
            <p className="type">{props.product.charAt(0).toUpperCase() + props.product.slice(1)}</p>
            <p className="model">{props.model.charAt(0).toUpperCase() + props.model.slice(1)+"."}</p>
            <p className="price">${props.price}</p>
            <div className="button">Ver más</div>
        </Link>
    );
}






export default  Card;