// Importa useState y useEffect de 'react'
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import agregar from './img/agregar.png';
import modificar from './img/modificar.png';
import eliminar from './img/eliminar.png';
import Header from './components/header_control/header.js';
import './Api.css';
import { returnUri } from './uri.js';

function Api() {
    const [espacioUsado, setespacioUsado] = useState(0);
    const [error, setError] = useState(null);

    // Simula una llamada a la API para obtener el espacio usado
    useEffect(() => {
        const fetchData = async (enlace) => {
            fetch(enlace, {
                method: 'GET',
            })
                .then(response => response.json())
                .then(data => {
                    if (data.error) {
                        // Si el servidor envía un JSON con un campo "error", consideramos esto como un error
                        throw new Error(data.error);
                    } else {
                        console.log(data);
                        setespacioUsado(parseFloat(data.espacio));
                    }
                })
                .catch(error => {
                    // Manejar el error de la solicitud, incluyendo el caso en el que no haya respuesta del servidor
                    console.error('Error en la solicitud:', error.message);
                    setError(error.message);

                    // Puedes agregar aquí la lógica adicional que desees para manejar la falta de respuesta
                    // Por ejemplo, mostrar un mensaje al usuario o realizar otra acción específica.
                });
        }
        console.log(`${returnUri()}/api/disk`)
        fetchData(`${returnUri()}/api/disk`)

    }, []); // El segundo argumento [] asegura que useEffect se ejecute solo una vez al montar el componente

    return (
        <div>
            <main>
                <Header></Header>
                <div className='containerApi'>
                    <div className='grid_1'>
                        <Link to='/api/agregar'>
                            <img src={agregar} alt='agregar'></img>
                            <p>Agregar</p>

                        </Link>
                        <Link to='/api/modificar'>

                            <img src={modificar} alt='modificar'></img>
                            <p>Modificar</p>

                        </Link>
                        <Link to='/api/eliminar'>

                            <img src={eliminar} alt='eliminar'></img>
                            <p>Eliminar</p>

                        </Link>
                    </div>
                    {error ? (
                        <div className='error-message'>
                            <p>Error al obtener el espacio usado. Por favor, inténtalo de nuevo.</p>
                        </div>
                    ) : (
                        // Mostrar contenido normal si no hay error


                        <div className='porcentaje'>
                            <h3>Espacio:</h3>
                            <meter value={espacioUsado} max={100}></meter>
                            <div className='infoA'>
                                <div>
                                    <div className='esfera'></div>
                                    <p>Usado: {espacioUsado}%</p>
                                </div>
                                <div>
                                    <div className='esfera'></div>
                                    <p>Restante: {100 - espacioUsado}%</p>
                                </div>
                                <p>Total 10 GB</p>
                            </div>
                        </div>

                    )}

                </div>
            </main>
        </div>
    );
}

export default Api;