import './Modificar.css';
import Header from './components/header_control/header.js';
import Card from './components/card_control/card';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import gif from '../src/img/loading.gif'

import { returnUri } from './uri.js';

function Modificar() {
    const { id } = useParams();
    const [data, setData] = useState([]);
    const [conexionExito, setConexion] = useState(false);
    useEffect(() => {
        const fetchData = async (enlace) => {
            try {
                const response = await fetch(enlace, {
                    method: 'GET',
                });

                if (!response.ok) {
                    throw new Error('Error de conexión. Asegúrate de que el servidor esté en ejecución.');
                }

                const jsonData = await response.json();
                setData(jsonData);
                setConexion(true);

                if (jsonData.error) {
                    throw new Error(jsonData.error);
                } else {
                    console.log(jsonData);
                }
            } catch (error) {
                console.log('Connection error');
                
                console.error('Error fetching data:', error);
            }
        };

        fetchData(`${returnUri()}/data`);
    }, []);

    useEffect(() => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    }, [id]);
    if (conexionExito) {
        return (
            <div>
                <Header></Header>
                <main>
                    <h1>Modificar Producto</h1>
                    <h2 className='name' id="niño">Niño🦸🏻‍♂️</h2>
                    <section className='section'>
                        {
                            data.map((e) => {
                                if (e.genero === "niño") {
                                    return <Card key={e.id + "B"} delete={false} image={e.photos[0]} product={e.producto} model={e.modelo} price={e.precioInfantil} id={e.id} stock={e.stock === "true"}></Card>
                                } else {
                                    return null;
                                }
                            })
                        }
                    </section>
                    <h2 className='name' id="niña">Niña🦹🏻‍♀️</h2>
                    <section className='section'>
                        {
                            data.map((e) => {
                                if (e.genero === "niña") {
                                    return <Card key={e.id + "B"} delete={false} image={e.photos[0]} product={e.producto} model={e.modelo} price={e.precioInfantil} id={e.id} stock={e.stock === "true"}></Card>
                                } else {
                                    return null;
                                }
                            })
                        }
                    </section>
                    <h2 className='name' id="caballero">Caballero👨🏻</h2>
                    <section className='section'>
                        {
                            data.map((e) => {
                                if (e.genero === "caballero") {
                                    return <Card key={e.id + "B"} delete={false} image={e.photos[0]} product={e.producto} model={e.modelo} price={e.precioInfantil} id={e.id} stock={e.stock === "true"}></Card>
                                } else {
                                    return null;
                                }
                            })
                        }
                    </section>
                    <h2 className='name' id="dama">Dama👩🏻</h2>
                    <section className='section'>
                        {
                            data.map((e) => {
                                if (e.genero === "dama") {
                                    return <Card key={e.id + "B"} delete={false} image={e.photos[0]} product={e.producto} model={e.modelo} price={e.precioInfantil} id={e.id} stock={e.stock === "true"}></Card>
                                } else {
                                    return null;
                                }
                            })
                        }
                    </section>
                </main>
            </div>
        );
    } else {
        return <div className='cargando'><img src={gif} alt='cargando' /></div>;
    }

}

export default Modificar;

