
import Home from './Home';
import { Route, Routes } from 'react-router-dom';
import Catalogo from './Catalogo';
import Contacto from './Contacto';
import Product from './Product'

//Rutas de panel de control
import Api from './Api';
import Agregar from './Agregar'
import Modificar from './Modificar'
import View from './View'
import Eliminar from './Eliminar'

function App() {
  return (
    <Routes className="App">
      <Route path="/" element={<Home />} />
      <Route path="catalogo" element={<Catalogo />} />
      <Route path="catalogo/:id" element={<Catalogo />} />
      <Route path="contacto" element={<Contacto />} />
      <Route path="product/:id" element={<Product />} />
      <Route path="api/*" element={<ApiRoutes />} />
    </Routes>
  );
}

function ApiRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Api />} />
      <Route path="agregar" element={<Agregar />} />
      <Route path="modificar" element={<Modificar />} />
      <Route path='view/:id' element={<View />} />
      <Route path='eliminar' element={<Eliminar />} />
    </Routes>
  );
}

export default App;
