import './Modificar.css';
import Header from './components/header_control/header.js';
import Card from './components/card_control/card';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import gif from '../src/img/loading.gif'

import exito from './img/exito.png';
import error from './img/error.png'

import { returnUri } from './uri.js';

function Eliminar() {
    const [uri, setUri] = useState("");
    useEffect(() => {
        setUri(`${returnUri()}/api/delete`);
    }, []);
    const { id } = useParams();
    const [data, setData] = useState([]);
    const [conexionExito, setConexion] = useState(false);

    const cargaData = async (enlace) => {
        try {
            const response = await fetch(enlace, {
                method: 'GET',
            });

            if (!response.ok) {
                throw new Error('Error de conexión. Asegúrate de que el servidor esté en ejecución.');
            }

            const jsonData = await response.json();
            setData(jsonData);
            setConexion(true);

            if (jsonData.error) {
                throw new Error(jsonData.error);
            } else {
                console.log(jsonData);
            }
        } catch (error) {
            console.log('Connection error');
            console.error('Error fetching data:', error);
        }
    };
    useEffect(() => {
        cargaData(`${returnUri()}/data`);
    }, []);

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [deleteId, setDeleteId] = useState(null);

    const [exitoVisible, setExitoVisible] = useState(false);
    const [errorVisible, setErrorVisible] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const OkExito = () => {
        setExitoVisible(false);
        console.log("Entre Aqui");
        cargaData(`${returnUri()}/data`);
    };

    const okError = () => {
        // Lógica para cancelar eliminación (si es necesario)
        setErrorVisible(false);
        setErrorMessage('');
        console.log("Entre aqui error")
    };


    const handleClick = (props) => {
        if (props.delete) {
            // Mostrar el modal de confirmación
            setIsModalVisible(true);
            setDeleteId(props.id);
        } else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    };

    const handleConfirmDelete = () => {
        console.log("Deleting ID:", deleteId);
        deleteData()
        // Ocultar el modal después de la confirmación
        setIsModalVisible(false);
        setDeleteId(null);
    };

    const handleCancelDelete = () => {
        // Ocultar el modal en caso de cancelación
        setIsModalVisible(false);
        setDeleteId(null);
    };


    useEffect(() => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    }, [id]);

    const deleteData = () => {
        const data = {
            id: deleteId
        }


        fetch(`${returnUri()}/api/delete`, {
            method: 'DELETE',
            headers: {
                'Content-Type':'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Error de conexión. Asegúrate de que el servidor esté en ejecución.');
                }
                return response.json();
            })
            .then(data => {
                if (data.error) {
                    throw new Error(data.error);
                } else {
                    console.log(data);
                    setExitoVisible(true);
                }
            })
            .catch(error => {
                setErrorMessage(error.message);
                setErrorVisible(true);
                throw new Error(error);
            });

    };
    if (conexionExito) {
        return (
            <div>
                <Header></Header>
                <main>
                    <h1>Modificar Producto</h1>
                    <h2 className='name' id="niño">Niño🦸🏻‍♂️</h2>
                    <section className='section'>
                        {
                            data.map((e) => {
                                if (e.genero === "niño") {
                                    return <Card key={e.id + "B"} delete={true} image={e.photos[0]} product={e.producto} model={e.modelo} price={e.precioInfantil} id={e.id} stock={e.stock === "true"} handleClick={handleClick}></Card>
                                } else {
                                    return null;
                                }
                            })
                        }
                    </section>
                    <h2 className='name' id="niña">Niña🦹🏻‍♀️</h2>
                    <section className='section'>
                        {
                            data.map((e) => {
                                if (e.genero === "niña") {
                                    return <Card key={e.id + "B"} delete={true} image={e.photos[0]} product={e.producto} model={e.modelo} price={e.precioInfantil} id={e.id} stock={e.stock === "true"} handleClick={handleClick}></Card>
                                } else {
                                    return null;
                                }
                            })
                        }
                    </section>
                    <h2 className='name' id="caballero">Caballero👨🏻</h2>
                    <section className='section'>
                        {
                            data.map((e) => {
                                if (e.genero === "caballero") {
                                    return <Card key={e.id + "B"} delete={true} image={e.photos[0]} product={e.producto} model={e.modelo} price={e.precioInfantil} id={e.id} stock={e.stock === "true"} handleClick={handleClick}></Card>
                                } else {
                                    return null;
                                }
                            })
                        }
                    </section>
                    <h2 className='name' id="dama">Dama👩🏻</h2>
                    <section className='section'>
                        {
                            data.map((e) => {
                                if (e.genero === "dama") {
                                    return <Card key={e.id + "B"} delete={true} image={e.photos[0]} product={e.producto} model={e.modelo} price={e.precioInfantil} id={e.id} stock={e.stock === "true"} handleClick={handleClick}></Card>
                                } else {
                                    return null;
                                }
                            })
                        }
                    </section>
                    {isModalVisible && (
                        <div className="modal">
                            <h2>Eliminar</h2>
                            <p>¿Esta seguro que desea eliminar el producto?</p>
                            <div>
                                <button onClick={handleConfirmDelete}>Si</button>
                                <button onClick={handleCancelDelete}>No</button>
                            </div>
                        </div>
                    )}
                </main>
                {exitoVisible && (
                    <div className="modal_1">
                        <img alt="exito" src={exito} />
                        <h2>Éxito</h2>
                        <p>El producto se ha eliminado correctamente.</p>
                        <button onClick={OkExito}>OK</button>
                    </div>
                )}

                {/* Modal de error */}
                {errorVisible && (
                    <div className="modal_1">
                        <img alt="error" src={error} />
                        <h2>Error</h2>
                        <p>Ocurrió un error al intentar eliminar el producto.</p>
                        <p>{errorMessage}</p>
                        <button onClick={okError}>OK</button>
                    </div>
                )}
            </div>
        );
    } else {
        return <div className='cargando'><img src={gif} alt='cargando' /></div>;
    }

}

export default Eliminar;

