import './footer.css';
import face from "./icons/facebook.svg"
import insta from "./icons/instagram.svg"
import what from "./icons/whatsapp.svg"
import mail from "./icons/mail.svg"

function footer() {
    return (
        <footer>
            <hr></hr>
            <div className="contacto">
                <a href="https://www.facebook.com/tiburoncitojeansmoda">
                    <img src={face} alt="facebook" />
                </a>
                <a href="https://www.instagram.com/tiburoncito_jeans/?hl=es-la">
                    <img src={insta} alt="instagram" />
                </a>
                <a href="https://api.whatsapp.com/send?phone=5212228836668">
                    <img src={what} alt="whatsapp" />
                </a>
                <a href="mailto:tiburoncito.jeans@outlook.es">
                    <img src={mail} alt="mail" />
                </a>
            </div>
            <hr></hr>
            <p>Tiburoncito Jeans</p>
            <hr></hr>
            <p className='copy'>Realizado por <a href='https://bitcoders.site/'>BitCoders</a></p>
        </footer>
    );
}

export default footer;