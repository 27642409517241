
import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import { returnUri } from '../../uri';
const handleClick = (props) => {
    if (props.delete) {
        // Llama a la función handleClick si está definida en las props
        props.handleClick(props);
    }
};

function Card(props) {
    const [uri, setUri] = useState('');
    useEffect(() => {
        setUri(`${returnUri()}`)
    }, []);
    return (
        <Link key={props.id + "A"} className="container" to={props.delete ? '' : "/api/view/" + props.id} onClick={() => handleClick(props)}>
            <div className="container-img">
                <img alt="s" src={uri + "/imgs/" + props.image}></img>
                {props.stock ? null : <span className='agotado'>Agotado</span>}
            </div>
            <p className="type">{props.product.charAt(0).toUpperCase() + props.product.slice(1)}</p>
            <p className="model">{props.model.charAt(0).toUpperCase() + props.model.slice(1) + "."}</p>
            <p className="price">${props.price}</p>
            {props.delete ? (
                <div className="button eliminar">
                    Eliminar
                </div>
            ) : (
                <div className="button">
                    Modificar
                </div>
            )}
        </Link>
    );
}

export default Card;