import './Contacto.css';
import support from './img/support.svg'
import phon from './img/phone.png'
import correo from './components/footer/icons/mail.svg'
import what from './components/footer/icons/whatsapp.svg'
import face from './components/footer/icons/facebook.svg'
import insta from './components/footer/icons/instagram.svg'
import Header from './components/header/header.js';
import Footer from './components/footer/footer.js'


function Contacto() {
    return (
        <div>
            <Header></Header>
            <main>
                <div className="contenido">
                    <h1>Nuestro contacto</h1>
                    <div className="cuadro">
                        <img src={support} alt='llamanos'></img>
                        <section className='info'>
                            <a href="tel:2228836668" className="phone">
                                <img src={phon} alt='telefono'></img>
                                <p>222 883 6668</p>
                            </a>
                            <a href="mailto:tiburoncito.jeans@outlook.es" className="e-mail">
                                <img src={correo} alt="mail" />
                                <p>tiburoncito.jeans@outlook.es</p>
                            </a>
                            <a href="https://api.whatsapp.com/send?phone=5212228836668" className="whats">
                                <img src={what} alt="whatsapp" />
                                <p>Escribenos</p>
                            </a>
                        </section>
                    </div>
                    <h1>Redes sociales</h1>
                    <section className="redes">
                        <a href="https://www.facebook.com/tiburoncitojeansmoda">
                            <img src={face} alt="facebook" />
                            <p>Like en Facebook</p>
                        </a>
                        <a href='https://www.instagram.com/tiburoncito_jeans/?hl=es-la'>
                            <img src={insta} alt="instagram" />
                            <p>Siguenos en Instagram</p>
                        </a>
                    </section>
                    <h1>¡Visitanos!</h1>
                        <box>
                            <p>📍 Estamos ubicados en:</p>
                            <p>Concepción 317, Centro, 74110 San Matías Tlalancaleca, Pue.</p>
                        </box>
                </div>
            </main>
            <Footer></Footer>
        </div>
    );
}

export default Contacto;
